import React from "react"
import Head from "../components/Head"
import Container from "../components/Container.js"
import HeaderTitle from "../components/HeaderTitle.js"
import { Link } from "gatsby"
import TableOfContents from "../components/TableOfContents"
import TableOfContentsHeader from "../components/TableOfContents/Header"
import TableOfContentsRow from "../components/TableOfContents/Row"
import TableOfContentsSection from "../components/TableOfContents/Section"
import TextSectionLink from "../components/TextContainer/Link"

export default function BonusProjectsTableOfContents() {
  return (
    <>
      <Head />
      <Container lessVerticalSpace>
        <HeaderTitle
          title={<Link to="/#bonus-projects">JavaScript Simplified</Link>}
          subtitle="Bonus Projects"
        />
      </Container>
      <TableOfContents>
        <TableOfContentsSection>
          <TableOfContentsHeader>
            Beginner Focused Projects
          </TableOfContentsHeader>
          <TableOfContentsRow duration="50:03" title="Tooltip Library" />
          <TableOfContentsRow duration="1:06:01" title="Trello Clone" />
          <TableOfContentsRow duration="1:11:08" title="Pictionary Clone" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>
            Advanced Focused Projects
          </TableOfContentsHeader>
          <TableOfContentsRow duration="2:04:49" title="Custom Database" />
          <TableOfContentsRow duration="1:22:15" title="Ecommerce" />
          <TableOfContentsRow
            duration="1:14:50"
            title="Google Calendar Clone"
          />
        </TableOfContentsSection>
        <div className="mt-12">
          <div className="mb-3">
            <TextSectionLink local href="/beginner-table-of-contents">
              Beginner Course Video List
            </TextSectionLink>
          </div>
          <div className="mb-3">
            <TextSectionLink local href="/advanced-table-of-contents">
              Advanced Course Video List
            </TextSectionLink>
          </div>
          <div>
            <TextSectionLink local href="/interview-table-of-contents">
              Interviews Video List
            </TextSectionLink>
          </div>
        </div>
      </TableOfContents>
      <div className="my-12 text-2xl text-center">
        <TextSectionLink local href="/#bonus-projects">
          Back To Landing Page
        </TextSectionLink>
      </div>
    </>
  )
}
